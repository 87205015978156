import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { SingleMessage, Text, Link } from '@comicrelief/component-library';
import {
  External,
  Internal,
} from '@comicrelief/component-library/dist/components/Atoms/Icons';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

const Anchor = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize('xxs')};
  &,
  :hover {
    color: ${({ theme }) => theme.color('grey_light')};
    border-bottom-color: ${({ theme }) => theme.color('grey_light')};
  }
`;

const CTA = styled(Link)`
  height: 40px;
`;
const Term = styled(Text)`
  margin-top: 0.7rem;
`;

const IndexPage = ({ data }) => {
  const { yoga, team } = data;
  const yogaSet = yoga.childImageSharp.fluid.srcSet;
  const yogaImage = yoga.childImageSharp.fallback.src;
  const yogaLow = yoga.childImageSharp.placeHolder.src;
  const seoImage = team.childImageSharp.seoImage.src;

  const teamSet = team.childImageSharp.fluid.srcSet;
  const teamImage = team.childImageSharp.fallback.src;
  const teamLow = team.childImageSharp.placeHolder.src;

  return (
    <Layout>
      <SEO pathname="" image={seoImage} />
      <SingleMessage
        backgroundColor="blue_dark"
        imageSet={yogaSet}
        image={yogaImage}
        imageLow={yogaLow}
        imageAltText="A young woman doing yoga"
        copyFirst
        fullImage
        vhFull
      >
        <Text tag="h1" color="white" size="super" family="Anton" weight="400">
          YOUR DONATION WILL CHANGES LIVES
        </Text>
        <Text tag="p" color="white" size="s">
          Sport Relief brings the nation together to get active and raise life
          changing amounts of money through the power of sport.
        </Text>

        <span>
          <CTA
            href="https://donation.sportrelief.com/?cartId=SRNT"
            type="button"
            color="red"
            target="self"
            icon={<Internal colour="white" />}
            rel="noopener"
          >
            I'd like to give now
          </CTA>
        </span>

        <Term tag="p" color="grey_light" size="xxs">
          <Anchor href="/sms">See text to donate terms and conditions </Anchor>
          {' '}
          and
          { ' ' }
          <Anchor href="/terms-of-use"> terms of use </Anchor>
        </Term>
      </SingleMessage>
      <SingleMessage
        backgroundColor="white"
        imageSet={teamSet}
        image={teamImage}
        imageLow={teamLow}
        imageAltText="A group of person"
      >
        <Text tag="h2" color="black" family="Anton" weight="400" height="3rem">
          DON'T MISS OUT
        </Text>
        <Text tag="p" color="black">
          Tune in to BBC One and BBC Two for a host of Sport Relief
          entertainment.
        </Text>
        <span>
          <CTA
            href="https://www.bbc.co.uk/iplayer"
            type="button"
            color="red"
            target="blank"
            rel="noopener"
            icon={<External colour="white" />}
          >
            Watch on iPlayer now
          </CTA>
        </span>
      </SingleMessage>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    yoga: PropTypes.shape({
      childImageSharp: PropTypes.shape({}),
    }).isRequired,
    team: PropTypes.shape({
      childImageSharp: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
};
export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(quality: 85) {
        srcSet
      }
      fallback: fixed {
        src
      }
      placeHolder: fixed(width: 50) {
        src
      }
      seoImage: resize {
        src
      }
    }
  }
`;

export const imageQuery = graphql`
  {
    yoga: file(relativePath: { regex: "/yoga_1400x1020_4-3_2x.jpg/" }) {
      ...fluidImage
    }

    team: file(relativePath: { regex: "/img_1240x1020.jpg/" }) {
      ...fluidImage
    }
  }
`;
